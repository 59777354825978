import React, {
  Fragment,
} from 'react'

import { 
  Navigation,
} from "@shopify/polaris"


function Nav(props) {
  const { selectedTabIndex } = props
  return (
    <Navigation location="/app">
      <Navigation.Section
        items={[
          {
            label: 'Analytics',
            selected: selectedTabIndex === 0,
            // icon: HomeMajor,
          },
          {
            url: '/app/offers',
            label: 'Orders',
            // icon: OrdersMajor,
            selected: selectedTabIndex === 1,
          },
        ]}
      />
    </Navigation>
  );
}

export default Nav;